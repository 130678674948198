@import '../../../styles/marketplace.css';

.container {
}

.label {
  width: 62px;
  height: 26px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  font-size: 12px;

  /* font-family: Sofia Pro; */

  @media (--viewportLarge) {
    font-size: 18px;
    width: 92px;
    height: 33px;
  }
}

.pendingLabel {
  background: #fde5d1;
  color: #ef8451;
}

.paidLabel {
  background: #dfefd7;
  color: #399277;
}

.failedLabel {
  background: #ffdecf;
  color: #ff4747;
}

.inTransitLabel {
  background: #fdddc3;
  color: #f27054;
}
